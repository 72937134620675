<template>
  <h3 class="title is-3 is-size-4-mobile is-size-5-tablet" :class="customClass">{{ title }}</h3>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      customClass: {
        type: String,
        default: ""
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>